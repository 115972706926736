<template>
    <div class="main packages-page">
        <div class="header__main ">
            <div class="header__body main-width">

                <h2 class="header__title text-center">{{ $t("services.documents_title") }} </h2>

                <div class="row docrow" v-if="true || getRole() == 'customer'">
                    <div class="col-lg-6 offset-lg-3">
                        <div class="docblock">
                            <div class="row">
                                <div class="col-12">
                                    <a target="_blank" href="#">
                                    <img src="img/gov.svg" />
                                    &nbsp;
                                    <span>Для государственных учреждений</span>
                                    </a>
                                </div>
                            </div>
                            <div class="row text-left">
                                <div class="col-sm-12">
                                    <div class="d-block" style="display:none;padding:20px 20px;">
                                        <ul>
                                            <li>
                                                <a target="_blank" href="api/uicommand/download/file?id=FD512A7A-9A71-49D7-8486-2F232254F643">
                                                <span>Коммерческое предложение на 2023 год</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_blank" href="api/uicommand/download/file?id=131E9246-6CD6-4860-8E04-2836A85DBB16">
                                                <span>Договор о государственных закупках услуг</span>
                                                </a>
                                            </li>
                                            <li>
                                                Техническая спецификация:&nbsp;
                                                <!-- <a target="_blank" href="landing/offer.docx"><u>Бизнес</u></a>, -->
                                                <a target="_blank" href="landing/eoz-ts-audit-ru.docx"><u>Аудитор</u></a>
                                            </li>
                                            <li>
                                                Авторское право:&nbsp;
                                                <a target="_blank" href="landing/author.pdf"><u>Свидетельство</u></a>,
                                                <a target="_blank" href="landing/author-lic.pdf"><u>Договор</u></a>
                                            </li>
                                        </ul>
                                        <hr>
                                        <span class="text-warning">На основании пп.3 п.3 ст.39 закона о государственных закупках внести изменения и дополнения в годовой план закупок (из одного источника путем прямого заключения договора по интеллектуальной собственности) (КОД ЕНС ТРУ 620920.000.000013).</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row docrow" v-if="true || isRole('supplier')">
                    <div class="col-lg-6 offset-lg-3">
                        <div class="docblock">
                            <div class="row">
                                <div class="col-12">
                                    <a target="_blank" href="api/uicommand/download/file?id=63E2DC6C-871C-4FD2-B9DA-A9AB5BAD49B8">
                                    <img src="img/biz.svg" />
                                    &nbsp;
                                    <span>Для бизнеса</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row docrow" v-if="false">
                    <div class="col-lg-6">
                        <div class="docblock">
                            <div class="row">
                                <div class="col-12">
                                    <a target="_blank" href="#" @click.prevent="showDocs = !showDocs; $event.preventDefault();">
                                    <img src="img/gov.svg" />
                                    &nbsp;
                                    <span>Для государственных учреждений</span>
                                    </a>
                                </div>
                            </div>
                            <div class="row text-left">
                                <div class="col-sm-12">
                                    <div :class="showDocs ? 'd-block' : ''" style="display:none;padding:20px 20px;">
                                        <ul>
                                            <li>
                                                <a target="_blank" href="landing/kp2021.pdf">
                                                <span>Коммерческое предложение на 2021 год</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_blank" href="landing/dog2021.docx">
                                                <span>Договор о государственных закупках услуг</span>
                                                </a>
                                            </li>
                                            <li>
                                                Техническая спецификация:&nbsp;
                                                <a target="_blank" href="landing/eoz-ts-zakaz-ru.docx"><u>Заказчик</u></a>,
                                                <a target="_blank" href="landing/eoz-ts-audit-ru.docx"><u>Аудит</u></a>
                                            </li>
                                            <li>
                                                Авторское право:&nbsp;
                                                <a target="_blank" href="landing/author.pdf"><u>Свидетельство</u></a>,
                                                <a target="_blank" href="landing/author-lic.pdf"><u>Договор</u></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="docblock">
                            <div class="row">
                                <div class="col-12">
                                    <a target="_blank" href="landing/offer.docx">
                                    <img src="img/biz.svg" />
                                    &nbsp;
                                    <span>Для бизнеса</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h2 class="header__title text-center"> {{$t('mainPage.packForUsers')}} </h2>
                <div class="row no-gutters">
                    <div class="col-12 col-sm-6 col-md-3">
                        <div class="package">
                            <div class="package__img">
                                <img src="@/assets/img/icons/package_true.svg" alt="">
                            </div>
                            <div class="package__desc">
                                <p>{{$t('mainPage.packFor1')}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <div class="package">
                            <div class="package__img">
                                <img src="@/assets/img/icons/package_true.svg" alt="">
                            </div>
                            <div class="package__desc">
                                <p>{{$t('mainPage.packFor2')}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <div class="package">
                            <div class="package__img">
                                <img src="@/assets/img/icons/package_true.svg" alt="">
                            </div>
                            <div class="package__desc">
                                <p>{{$t('mainPage.packFor3')}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <div class="package package__last">
                            <div class="package__img">
                                <img src="@/assets/img/icons/package_true.svg" alt="">
                            </div>
                            <div class="package__desc">
                                <p>{{$t('mainPage.packFor4')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <section class="main-width">
                <div class="row no-gutters price-area">

                    <div class="col-12 col-sm-4 col-lg-4">
                        <div class="price">
                            <h2 class="price__title">{{$t('mainPage.individual')}}</h2>
                            <p class="price__desc">&nbsp;</p>
                            <div class="price__content ">
                                <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                                    <p>{{$t('mainPage.individual_desc')}}</p>
                                </div>
                            </div>
                            <div class="price__price">
                                <h3 class="price__cost">{{$t('mainPage.individual_price')}}</h3>
                                <p style="opacity: 0" class="price__cost-desc">тенге</p>
                                <a href="#" class="price__btn" @click.prevent="makeCall()">{{$t('mainPage.individual_call')}}</a>
                                <a style="opacity: 0"  data-v-3cacfa1e="" href="#" class="price__link">Посмотреть демонстрацию</a>
                            </div>
                        </div>
                    </div>

                  <div class="col-12 col-sm-4 col-lg-4">
                    <div class="price">
                      <h2 class="price__title">Аудитор</h2>
<!--                      <h2 class="price__title">{{$t('mainPage.individual')}}</h2>-->
                      <p class="price__desc">В зависимости от объемов закупок заказчика, цена пакета варьируется:</p>
                      <div style="padding-bottom: 33px;" class="price__content ">
                        <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                          <p>До 50 млн. тенге - 250 000 тенге</p>
                        </div>
                        <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                          <p>
                            от 50 млн. тенге до 100 млн. тенге -
                            <br>
                            500 000 тенге
                          </p>
                        </div>
                        <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                          <p>
                            от 100 млн. тенге  до 500 млн. тенге -
                            <br>
                            2 500 000 тенге
                          </p>
                        </div>
                        <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                          <p>
                            от 500 млн. тенге  до 1 млрд. тенге -
                            <br>
                            5 000 000 тенге
                          </p>
                        </div>
                      </div>
                      <div class="price__price">
                        <p class="price__cost-desc" style="opacity: 0">{{$t('mainPage.tenders_price')}}</p>
                        <a href="#" class="price__btn" @click.prevent="makeCall()">{{$t('mainPage.individual_call')}}</a>
                        <a style="opacity: 0"  data-v-3cacfa1e="" href="#" class="price__link">Посмотреть демонстрацию</a>
                      </div>
                    </div>
                  </div>

                    <div class="col-12 col-sm-4 col-lg-4" v-for="t in tariffs" :key="t.id">
                        <div class="price">
                            <template v-if="$i18n.locale== 'kz'">
                                <h2 class="price__title">{{ t.nameKz }}</h2>
                                <p class="price__desc">{{ t.contentDescriptionKz }}</p>
                                <div class="price__content ">
                                    <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                                        <p>{{ t.contentKz1 }}</p>
                                    </div>
                                    <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                                        <p>{{ t.contentKz2 }}</p>
                                    </div>
                                    <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                                        <p>{{ t.contentKz3 }}</p>
                                    </div>
                                    <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                                        <p>{{ t.contentKz4 }}</p>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <h2 class="price__title">{{ t.nameRu }}</h2>
                                <p class="price__desc">{{ t.contentDescriptionRu }}</p>
                                <div class="price__content ">
                                    <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                                        <p>{{ t.contentRu1 }}</p>
                                    </div>
                                    <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                                        <p>{{ t.contentRu2 }}</p>
                                    </div>
                                    <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                                        <p>{{ t.contentRu3 }}</p>
                                    </div>
                                    <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                                        <p>{{ t.contentRu4 }}</p>
                                    </div>
                                </div>
                            </template>
                            <div class="price__price">
                                <h3 class="price__cost">{{ t.price | sum }}</h3>
                                <p class="price__cost-desc">{{$t('mainPage.tenders_price')}}</p>
                                <a href="#" class="price__btn" @click.prevent="makeOrder(t)">{{$t('mainPage.tenders_btn')}}</a>
                                <a href="#" class="price__link" @click.prevent="openUrl(t.url)">{{$t('mainPage.tenders_link')}}</a>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
    </div>
</template>

<script>

import mixOrder from '@/mixins/order'
import {mapGetters} from 'vuex'

    export default {
        mixins: [mixOrder],
        name: 'Packages',
        data() {
            return {
                showDocs: false,
            };
        },
        computed: {
            ...mapGetters([
                'userRole'
            ]),
        },
        methods: {
            getRole() {
                return this.userRole;
            },
            makeCall() {
                this.$root.$emit('callback-show');
            },
            isRole(role) {
                return this.userRole === role;
            }

        }
    }
</script>

<style lang="scss" scoped>

        .docrow {
            margin-bottom: 50px;
        }
        .docblock {
            background: #385e86;
            padding: 10px 10px;
            text-align: center;
            color: white;
        }
        .docblock a {
            color: white;
        }
        .docblock a:hover span {
            text-decoration: underline;
        }
        .docblock .dropdown button,
        .show > .btn.dropdown-toggle {
            background: transparent;
            border: 0;
        }
        .docblock .dropdown .dropdown-menu a {
            color: black;
        }
</style>